<template>
  <div class="disclaimer">
    <div class="disclaimer__content">
      <header>{{ notice }}</header>

      <article v-html="noticeContent"></article>

      <header>{{ header }}</header>
      <article v-for="(paragraph, index) in paragraphs" :key="index">
        <p>{{ paragraph }}</p>
      </article>

      <header>{{ accessibility.title }}</header>
      <article>{{ accessibility.text }}</article>
      <hr style="margin-top: 60px; border: 1px solid #dddddd" />
      <h5 style="margin-top: 20px; color: #303030">{{ updatedAt }}</h5>
    </div>
  </div>
</template>

<script>
import { content } from "./content";
import { mapGetters } from "vuex";

export default {
  name: "Contact",
  computed: {
    ...mapGetters({
      language: "localize/language"
    }),
    header: function() {
      return content[this.language].header;
    },
    paragraphs: function() {
      return content[this.language].paragraph;
    },
    notice: function() {
      return content[this.language].notice;
    },
    noticeContent: function() {
      return content[this.language].noticeContent;
    },
    accessibility: function() {
      return content[this.language].accessibility;
    },
    updatedAt: function() {
      return content[this.language].updatedAt;
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less">
.disclaimer {
  flex: 1;
  width: 100%;
  overflow: auto;

  &__content {
    margin: 0 auto;
    padding: 30px 60px;
    width: 1200px;
    min-height: 100%;
    box-sizing: border-box;
    background-color: #fffef6;

    header {
      margin-top: 25px;
      margin-bottom: 25px;
      font-size: 2em;
      color: #fdb814;
      font-weight: bold;
    }

    article {
      font-size: 1.5em;
      line-height: 1.5em;
      color: #303030;
      text-align: justify;
    }
  }
}

@media screen and (max-width: 1024px) {
  .disclaimer {
    &__content {
      width: 100%;

      article {
        font-size: 1em;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .disclaimer {
    &__content {
      width: 100%;
      padding: 15px 20px;

      header {
        font-size: 1em;
      }

      article {
        font-size: 1em;
        line-height: 1.5em;
      }
    }
  }
}
</style>
